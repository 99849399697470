@use "/website/assets/scss/utilities/variables" as var;
@use "/website/assets/scss/utilities/mixins" as mix;

$main-header-height: 117px;

.hero-section-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 640px;
    height: calc(100vh - #{$main-header-height});
    max-height: 700px;
    background: var.$electric-blue;

    &__arrow {
        position: absolute;
        bottom: -16px;
        left: 50%;
        transform: translateX(-50%);
        z-index: var.$zindex-dropdown;
    }

    &__character {
        display: none;
        position: absolute;
        bottom: -40px;
        left: 58%;
        //right: 30%;
        z-index: var.$zindex-dropdown;
    }
}

.hero-section {
    position: relative;
    color: var.$white;
    padding-top: 68px;
    padding-bottom: 68px;
    z-index: 0;

    &__character {
        z-index: -1;
        position: absolute;
        top: 0;
        right: 48px;
    }

    &__title {
        @include mix.font-headlines-h1();
        text-align: center;
        margin-bottom: 8px;
    }

    &__subtitle {
        @include mix.font-mobile-body-m();
        text-align: center;
        margin-bottom: 32px;
    }

    &__action-wrapper {
        text-align: center;
    }

    &__button {
        margin-bottom: 8px;
    }

    &__annotation {
        display: inline-block;
        width: 100%;
        @include mix.font-detail-medium();
    }
}

@media (min-width: var.$breakpoint-rule-tablet) {

    .hero-section-container {
        &__arrow {
            bottom: -40px;
        }

        &__character {
            display: block;
        }
    }

    .hero-section {
        margin-bottom: 0;

        &__character {
            display: none;
        }

        &__title {
            max-width: 790px;
            margin: 0 auto 24px;
        }

        &__subtitle {
            @include mix.font-desktop-body-l();
            max-width: 583px;
            margin: 0 auto 48px;
        }

        &__action-wrapper {
            text-align: center;
        }

        &__button.btn--block {
            width: auto;
            padding-left: 114px;
            padding-right: 114px;
        }

        &__annotation {
            display: inline-block;
            width: 100%;
            @include mix.font-detail-medium();
        }
    }
}

@media (min-width: var.$breakpoint-rule-desktop) {
    $main-header-height: 80px;

    .hero-section-container {
        height: calc(100vh - #{$main-header-height});
    }
}
