@charset "UTF-8";
/*
* z-index de la aplicación
*/
.section-container {
  padding: 0 15px;
}
.section-container__wrapper {
  margin: 0 auto;
}

@media (min-width: 768px) {
  .section-container {
    padding: 0 48px;
  }
}
@media (min-width: 992px) {
  .section-container {
    padding: 0 15px;
  }
  .section-container__wrapper {
    max-width: 1200px;
  }
}
/*
* z-index de la aplicación
*/
.hero-section-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 640px;
  height: calc(100vh - 117px);
  max-height: 700px;
  background: #1670E4;
}
.hero-section-container__arrow {
  position: absolute;
  bottom: -16px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
}
.hero-section-container__character {
  display: none;
  position: absolute;
  bottom: -40px;
  left: 58%;
  z-index: 1000;
}

.hero-section {
  position: relative;
  color: #FFFFFF;
  padding-top: 68px;
  padding-bottom: 68px;
  z-index: 0;
}
.hero-section__character {
  z-index: -1;
  position: absolute;
  top: 0;
  right: 48px;
}
.hero-section__title {
  font-size: 48px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0;
  text-align: center;
  margin-bottom: 8px;
}
@media (min-width: 768px) {
  .hero-section__title {
    font-size: 64px;
    line-height: 68px;
    letter-spacing: -1px;
  }
}
.hero-section__subtitle {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;
  text-align: center;
  margin-bottom: 32px;
}
.hero-section__action-wrapper {
  text-align: center;
}
.hero-section__button {
  margin-bottom: 8px;
}
.hero-section__annotation {
  display: inline-block;
  width: 100%;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
}

@media (min-width: 768px) {
  .hero-section-container__arrow {
    bottom: -40px;
  }
  .hero-section-container__character {
    display: block;
  }

  .hero-section {
    margin-bottom: 0;
  }
  .hero-section__character {
    display: none;
  }
  .hero-section__title {
    max-width: 790px;
    margin: 0 auto 24px;
  }
  .hero-section__subtitle {
    font-family: "Inter", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: 0;
    max-width: 583px;
    margin: 0 auto 48px;
  }
  .hero-section__action-wrapper {
    text-align: center;
  }
  .hero-section__button.btn--block {
    width: auto;
    padding-left: 114px;
    padding-right: 114px;
  }
  .hero-section__annotation {
    display: inline-block;
    width: 100%;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media (min-width: 992px) {
  .hero-section-container {
    height: calc(100vh - 80px);
  }
}
/*
* z-index de la aplicación
*/
.introduction-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 88px;
}
.introduction-section__background {
  align-self: stretch;
  line-height: 0;
  margin: 0 -15px;
}
.introduction-section__background img {
  width: 100%;
}
.introduction-section__title {
  font-size: 32px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -1px;
  margin-bottom: 24px;
}
@media (min-width: 768px) {
  .introduction-section__title {
    font-size: 56px;
    line-height: 60px;
  }
}
.introduction-section__description {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;
  margin-bottom: 48px;
}
.introduction-section__button-test.btn.btn--block {
  width: auto;
  padding-left: 44px;
  padding-right: 44px;
  margin-bottom: 16px;
}
.introduction-section__button-itineraries {
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .introduction-section-container {
    overflow: hidden;
    z-index: 0;
  }

  .introduction-section {
    position: relative;
    padding-top: 188px;
    padding-bottom: 206px;
  }
  .introduction-section__background {
    z-index: -1;
    position: absolute;
    bottom: 0;
    left: -160px;
    right: -205px;
    min-width: 100%;
  }
  .introduction-section__description {
    font-family: "Inter", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0;
    max-width: 584px;
  }
  .introduction-section__button-test.btn.btn--block {
    width: auto;
    padding-left: 73px;
    padding-right: 73px;
    margin-bottom: 24px;
  }
}
/*
* z-index de la aplicación
*/
.learn-section-container {
  background: #F6F6F6;
}

.learn-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 128px;
  padding-bottom: 128px;
}
.learn-section__image {
  width: 250px;
  margin-bottom: 32px;
}

.message-card {
  text-align: center;
}
.message-card__title {
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.5px;
  margin-bottom: 24px;
}
@media (min-width: 768px) {
  .message-card__title {
    font-size: 32px;
    line-height: 36px;
    letter-spacing: -1px;
  }
}
.message-card__paragraph {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;
  margin-bottom: 32px;
}
.message-card__link {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.01em;
}

@media (min-width: 768px) {
  .learn-section {
    max-width: 994px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 200px;
    padding-bottom: 200px;
  }
  .learn-section__image {
    flex: 1 1 50%;
    max-width: 378px;
    padding-right: 30px;
    margin-bottom: 0;
    margin-right: 32px;
  }
  .learn-section__message {
    flex: 1 1 50%;
  }

  .message-card {
    text-align: inherit;
    max-width: 481px;
  }
  .message-card__paragraph {
    font-family: "Inter", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0;
    margin-bottom: 48px;
  }
  .message-card__link {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: -0.01em;
  }
}