@use "/website/assets/scss/utilities/variables" as var;
@use "/website/assets/scss/utilities/mixins" as mix;

.learn-section-container {
    background: var.$light-grey;
}

.learn-section {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: 128px;
    padding-bottom: 128px;

    &__image {
        width: 250px;
        margin-bottom: 32px;
    }
}

.message-card {
    text-align: center;

    &__title {
        @include mix.font-headlines-h4();
        margin-bottom: 24px;
    }

    &__paragraph {
        @include mix.font-mobile-body-m();
        margin-bottom: 32px;
    }

    &__link {
        @include mix.font-input-textlink();
    }
}

@media (min-width: map-get(var.$grid-breakpoints, 'md')) {
    .learn-section {
        max-width: 994px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        padding-top: 200px;
        padding-bottom: 200px;

        &__image {
            flex: 1 1 50%;
            max-width: #{348px + 30px};
            padding-right: 30px;

            margin-bottom: 0;
            margin-right: 32px;
        }

        &__message {
            flex: 1 1 50%;
        }
    }

    .message-card {
        text-align: inherit;
        max-width: 481px;

        &__paragraph {
            @include mix.font-desktop-body-m();
            margin-bottom: 48px;
        }

        &__link {
            @include mix.font-input-textlink();
        }
    }
}
