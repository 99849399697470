@use "/website/assets/scss/utilities/variables" as var;
@use "/website/assets/scss/utilities/mixins" as mix;

.introduction-section-container {
}

.introduction-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-align: center;

    padding-top: 88px;

    &__background {
        align-self: stretch;
        line-height: 0;
        margin: 0 -15px;

        img {
            width: 100%;
        }
    }

    &__title {
        @include mix.font-headlines-h2();
        margin-bottom: 24px;
    }

    &__description {
        @include mix.font-mobile-body-m();
        margin-bottom: 48px;
    }

    &__button-test.btn.btn--block {
        width: auto;
        padding-left: 44px;
        padding-right: 44px;

        margin-bottom: 16px;
    }

    &__button-itineraries {
        margin-bottom: 30px;
    }
}

@media (min-width: map-get(var.$grid-breakpoints, 'md')) {
    .introduction-section-container {
        overflow: hidden;
        z-index: 0;
    }

    .introduction-section {
        position: relative;
        padding-top: 188px;
        padding-bottom: 206px;

        &__background {
            z-index: -1;
            position: absolute;
            bottom: 0;
            left: #{-120px - 40px};
            right: #{-120px - 85px};
            min-width: 100%;
        }

        &__description {
            @include mix.font-desktop-body-m();
            max-width: 584px;
        }

        &__button-test.btn.btn--block {
            width: auto;
            padding-left: 73px;
            padding-right: 73px;

            margin-bottom: 24px;
        }
    }
}
